import React from "react";

import "./ubicacionesGrid.scss";
import { Trans } from "react-i18next";
import { useI18next } from "gatsby-plugin-react-i18next";

import Ubicacion from "./Ubicacion";
import { CSSTransition, SwitchTransition } from "react-transition-group";

import MaskSq1 from "../../svg/masks/maskSq1.svg";
import MaskSq2 from "../../svg/masks/maskSq2.svg";
import MaskSq3 from "../../svg/masks/maskSq3.svg";
import MaskSq4 from "../../svg/masks/maskSq4.svg";

export default function UbicacionesGrid({ restaurants }) {
	const { t } = useI18next();
	// console.log(restaurants);

	const [restaurantCities, setRestaurantCities] = React.useState(null);
	const [cityFilter, setCityFilter] = React.useState(false);

	// console.log(restaurantCities);
	// console.log(cityFilter);

	React.useEffect(() => {
		const acummulator = [];
		restaurants.nodes.forEach((restaurant) => {
			const city = restaurant.frontmatter.addressCity;
			if (!acummulator.includes(city)) acummulator.push(city);
		});
		setRestaurantCities(acummulator);
	}, [restaurants.nodes]);

	return (
		<div className="ubicacionesGrid">
			<MaskSq1 />
			<MaskSq2 />
			<MaskSq3 />
			<MaskSq4 />

			<div className="cityFilter">
				<h2>
					<Trans>Ubicaciones</Trans>
				</h2>
				<button
					onClick={() => setCityFilter(false)}
					className={`filterButton ${cityFilter === false ? "active" : ""}`}
					aria-label={t("Ver todo")}
				>
					<h3>
						<Trans>Ver todo</Trans>
					</h3>
				</button>
				{restaurantCities?.length > 0 &&
					restaurantCities.map((city) => {
						return (
							<button
								key={city}
								onClick={() => setCityFilter(city)}
								className={`filterButton ${
									cityFilter === city ? "active" : ""
								}`}
							>
								<h3>{city}</h3>
							</button>
						);
					})}
			</div>

			<div className="ubicaciones">
				<SwitchTransition mode="out-in">
					<CSSTransition key={cityFilter} timeout={400} classNames="fade">
						<div>
							{restaurants.nodes.map((restaurant) => (
								<Ubicacion
									restaurant={restaurant.frontmatter}
									visibility={
										!cityFilter
											? "visible"
											: restaurant.frontmatter.addressCity === cityFilter
											? "visible"
											: "invisible"
									}
								/>
							))}
						</div>
					</CSSTransition>
				</SwitchTransition>
			</div>
		</div>
	);
}
