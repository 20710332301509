import "../../styles/section.css";
import "./ubicaciones.css";

import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { graphql } from "gatsby";
import { useTranslation } from "react-i18next";

import Layout from "../../components/layout/Layout";
import Seo from "../../components/seo";
import SectionLanding from "../../components/section/sectionLanding";

// import Circulo1 from "../../svg/subrayados/circulo1.svg";
// import Ubicacion from "../../components/ubicaciones/Ubicacion";
// import IconDesplegable from "../../svg/icons/iconDesplegable.svg";
import UbicacionesGrid from "../../components/ubicaciones/ubicacionesGrid";

import Mask1 from "../../svg/masks/mask1.svg";
import Mask2 from "../../svg/masks/mask2.svg";
import Mask5 from "../../svg/masks/mask5.svg";
import MaskTop from "../../svg/masks/maskTop.svg";

export default function IndexPage({ data }) {
	const { t } = useTranslation();

	// const [showUbicaciones, setShowUbicaciones] = React.useState(false);

	return (
		<Layout footerText={t("¿Y si nos vamos?")} className="ubicaciones">
			<Seo title={t("Nuestros locales")} />

			<div className="blackOverlay" />
			<StaticImage
				src={`../../images/ubicaciones2.jpg`}
				className="landing-bg"
				alt="Section background"
				layout="fullWidth"
			/>
			<div className="landingSection">
				<SectionLanding
					title={t("Tu experiencia de conexión")}
					orbitalText={t("Ubicaciones")}
					subtitle={t("Acompáñanos en este viaje")}
				/>
			</div>

			{/* Importing masks to use them as clipping masks for backgrounds */}
			<Mask1 />
			<Mask2 />
			<Mask5 />

			<div className="section invertColors" id="ubicaciones">
				<MaskTop className="maskTop" />

				<UbicacionesGrid restaurants={data.restaurants} />

				{/* 
				<button
					id="ubicacionesTopLinksTitle"
					onClick={() => setShowUbicaciones(!showUbicaciones)}
					className={`${showUbicaciones ? "active" : ""}`}
					aria-label={t("Ubicaciones")}
				>
					<h2>
						<Trans>Ubicaciones</Trans>
						<IconDesplegable />
					</h2>
				</button>

				<div
					className={`ubicacionesTopLinks ${showUbicaciones ? "visible" : ""}`}
				>
					<a href="#valencia" aria-label="Valencia">
						<h2>
							<Trans>Valencia</Trans>
						</h2>
						<Circulo1 />
					</a>
					<a href="#gandia" aria-label="Gandía">
						<h2>
							<Trans>Gandía</Trans>
						</h2>
						<Circulo1 />
					</a>
				</div>

				<h1 id="valencia" className="ubicacionGroupTitle">
					Valencia
				</h1>
				<div className="ubicacionesGroup">
					<Ubicacion local="cortes" />
					<Ubicacion local="pascualgenis" />
					<Ubicacion local="rllull" />
					<Ubicacion local="alameda" />
				</div>

				<h1 id="gandia" className="ubicacionGroupTitle">
					Gandía
				</h1>
				<div className="ubicacionesGroup">
					<Ubicacion local="gandia" />
				</div> */}
			</div>
		</Layout>
	);
}

export const query = graphql`
	query ($language: String!) {
		locales: allLocale(filter: { language: { eq: $language } }) {
			edges {
				node {
					ns
					data
					language
				}
			}
		}

		restaurants: allMarkdownRemark(
			filter: { fields: { language: { eq: $language } } }
			sort: { order: ASC, fields: fileAbsolutePath }
		) {
			nodes {
				fields {
					language
				}
				frontmatter {
					id
					address1
					addressCity
					addressLink
					addressPostal
					deliveryLinks {
						name
						url
					}
					hours {
						miniBrunch
						brunch
						dinner
						afternoon
						lunch
					}
					maxPeople
					menuBrunch
					menuDia
					menuNavidad
					menuDegustacionNavidad
					menuGrupo
					menuNoche
					takeAwayLink
					subtitle
					title
					images {
						childImageSharp {
							gatsbyImageData
						}
					}
				}
			}
		}
	}
`;
